if (module.hot) {
    module.hot.accept();
}

import $ from "jquery";
import 'slick-carousel';

$(".kral__slider").slick(
    {
        useTransform: true,
        slidesToShow: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 4000,
        fade: true,
    }
);

window.addEventListener("resize", () => {
    $(".kral__slider").slick('setPosition');
});

let contentSections = document.querySelectorAll(".content-section");

let checkHash = () => {
    let hash = window.location.hash;
    let sectionId = hash.substr(1);
    let activeContentSection = document.getElementById(sectionId);
    setActiveSection(activeContentSection);
};

let unsetActiveSection = () => {
    let currentActiveSection = document.querySelector(".content-section.active");
    if (currentActiveSection) {
        document.querySelectorAll(".nav-link").forEach((link) => {
            link.classList.remove("active");
        });
        let main = document.querySelector("main");
        main.classList.remove("hidden");
        main.setAttribute("aria-hidden", false);

        document.querySelector("nav").classList.remove("has-active");
        currentActiveSection.classList.remove("active");
        currentActiveSection.setAttribute("aria-hidden", true);
    }
};

let setActiveSection = (activeContentSection) => {
    if (activeContentSection) {

        unsetActiveSection();
        let main = document.querySelector("main");
        main.classList.add("hidden");
        main.setAttribute("aria-hidden", true);
        let id = activeContentSection.getAttribute("id");
        document.querySelector(".nav-link[href='#" + id + "']").classList.add("active");
        document.querySelector("nav").classList.add("has-active");
        activeContentSection.setAttribute("aria-hidden", false);
        activeContentSection.classList.add("active");
    }
};


window.addEventListener("load", () => {
    checkHash();
    document.querySelectorAll("a[href^='#']").forEach((link) => {
        link.addEventListener("click", (e) => {
            e.preventDefault();
            let id = link.getAttribute("href").replace("#", "");

            if(link.matches(".nav-link")) {
                if(link.matches(".active")) {
                    unsetActiveSection();
                    return;
                }
            }

            let activeContentSection = document.getElementById(id);
            setActiveSection(activeContentSection);
        });
    });



    document.querySelectorAll(".close").forEach((close) => {
        close.addEventListener("click", (e) => {
            e.preventDefault();
            let main = document.querySelector("main");
            main.classList.remove("hidden");
            main.setAttribute("aria-hidden", false);
            unsetActiveSection();
        });
    });
});


